import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import MainCTA from '../../components/sections/main-cta'
import VideoPlayer from '../../components/common/video-player'
import RecipeBlock from '../../components/common/recipe-block'

const RachaelRayPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpCollaborator(filter: {slug: {eq: "rachael-ray"}}) {
        nodes {
          title
          featuredImage {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    width: 770
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          collaboratorFields {
            subtitle
            recipes {
              ... on WpRecipe {
                title
                slug
                recipes {
                  cookTime
                  prepTime
                }
                featuredImage {
                  node {
                    altText
                    publicUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 90
                          width: 1492
                          placeholder: BLURRED
                          formats: NO_CHANGE
                          layout: CONSTRAINED
                          aspectRatio: 1.5
                        )
                      }
                    }
                  }
                }
              }
            }
            videos {
              text {
                title
                subtitle
              }
              video
            }
          }
        }
      }
    }
  `)

  const collaborator = data.allWpCollaborator.nodes[0]
  const firstName = collaborator.title.split(' ')[0]
  const featuredImage = {
    data: collaborator.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: collaborator.featuredImage?.node?.alt || '',
  }
  const firstVideo = collaborator.collaboratorFields.videos[0]
  const restOfVideos = collaborator.collaboratorFields.videos.slice(1)
  const { recipes } = collaborator.collaboratorFields

  const VideoSection = ({ currentVideo }) => {
    return (
      <section className="py-1 py-sm-2 py-lg-3 py-xl-4 py-xxl-5 culinary-collective__video">
        <Container>
          <Row>
            <Col md="6" lg="5" xl="4" xxl="3">
              <h2>{currentVideo.text.title}</h2>
              <p>{currentVideo.text.subtitle}</p>
            </Col>
            <Col md="6" lg="7" xl="8" xxl="9">
              <VideoPlayer url={currentVideo.video} containerClass="rounded-4 overflow-hidden" ratio="16x9" buttonColor="danger" progressColor="danger" />
            </Col>
          </Row>
        </Container>
      </section>
    )
  }

  return (
    <Layout className="rachael-ray culinary-collective">
      <Seo title="Rachael Ray" />
      <header className="text-white bg-danger d-flex d-sm-grid">
        <Container fluid className="stack-child px-0">
          <Row>
            <Col sm={{ span: 6 }}>
              <GatsbyImage
                image={featuredImage.data}
                alt={featuredImage.alt}
                className="h-100"
              />
            </Col>
          </Row>
        </Container>
        <Container className="stack-child">
          <Row>
            <Col sm={{ span: 6, offset: 6 }} className="px-1 py-2">
              <h1 className="fw-bold">
                {collaborator.title}
                {' '}
                x meati™
              </h1>
              <div className="subtitle" dangerouslySetInnerHTML={{ __html: collaborator.collaboratorFields.subtitle }} />
            </Col>
          </Row>
        </Container>
      </header>
      <div className="bg-light text-danger">
        <VideoSection currentVideo={firstVideo} />
        <section className="culinary-collective__recipes py-1">
          <Container>
            <h2 className="mb-1">
              {firstName}
              's Recipes
            </h2>
            <Row className="gx-1 gy-2 gx-md-2 recipe-blocks recipe-blocks--collective recipe-blocks--flush">
              {recipes.map((node, index) => (
                <Col xs="6" lg="3" key={index}>
                  <RecipeBlock node={node} blockStyle="flush" />
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        {restOfVideos.map((video, index) => (
          <VideoSection key={index} currentVideo={video} />
        ))}
      </div>

      <MainCTA />
    </Layout>
  )
}

export default RachaelRayPage
